import style from "./css/header.module.scss";
import indexStyle from "../views/css/index.module.scss";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { grey } from "@mui/material/colors";
import Typography from "@mui/material/Typography";
import QQGroupDialog from "../components/qq-group-dialog";

function HeaderAd() {
  const topAds = [
    {
      adText: "【五洛云计算】高防云主机-0元购4核8G-IDC持证企业",
      adAddr: "https://www.wuluoyun.com/",
      endTime: "2025/2/21 23:59:59",
      qq: 22354346,
      price: 229,
    },
    {
      adText: "【数掘云算】高防4C4G500M带宽69元",
      adAddr: "https://www.gfvps.cn/activity.html",
      endTime: "2025/2/19 23:59:59",
      qq: 302775121,
      price: 249,
    },
    {
      adText: "【创梦网络】四川一手高防10年老商家-物理机低至170元-招代理",
      adAddr: "https://www.580dns.com/cart?fid=1&gid=3",
      endTime: "2025/2/21 23:59:59",
      qq: 490898739,
      price: 239,
    },
  ];

  return (
    <div className={style.headerTopInfoContainer}>
      {topAds
        .filter((v) => Date.now() < new Date(v.endTime).getTime())
        .map((v) => {
          return (
            <div>
              <a href={v.adAddr} target="_black">
                {v.adText}
              </a>
            </div>
          );
        })}
    </div>
  );
}

function Header() {
  const discordServerAddr = "https://discord.gg/BNpYMVX7Cd";
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  const [isScrollTop, setIsScrollTop] = useState(true);
  const [languageMenu, setLanguageMenu] = React.useState<null | HTMLElement>(
    null
  );
  const open = Boolean(languageMenu);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setLanguageMenu(event.currentTarget);
  };
  const handleToGithub = (event: React.MouseEvent<HTMLButtonElement>) => {
    window.open("https://github.com/MCSManager/MCSManager");
  };
  const handleToDiscord = () => {
    window.open(discordServerAddr);
  };
  const handleClose = () => {
    setLanguageMenu(null);
  };

  const handleSelectLang = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
    handleClose();
  };

  const isLocalhost =
    window.location.host.includes("localhost") ||
    window.location.host.includes("127.0.0.") ||
    window.location.href.includes("lang");

  useEffect(() => {
    function handleScrollEvent(event: Event) {
      setIsScrollTop(document.documentElement.scrollTop < 20);
    }
    window.addEventListener("scroll", handleScrollEvent);
    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
    };
  });

  function hoverMenu() {
    return (
      <Menu
        id="basic-menu"
        anchorEl={languageMenu}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleSelectLang("en_us")}>English</MenuItem>
        <MenuItem onClick={() => handleSelectLang("zh_cn")}>简体中文</MenuItem>
        {/* <MenuItem onClick={() => handleSelectLang("zh_tw")}>繁体中文</MenuItem> */}
      </Menu>
    );
  }

  const textColor = grey[900];
  const [visibleQQDialog, setVisibleQQDialog] = useState(false);

  const openQQGroupDialog = () => {
    setVisibleQQDialog(true);
  };

  return (
    <>
      <QQGroupDialog
        visible={visibleQQDialog}
        onClose={() => setVisibleQQDialog(false)}
      ></QQGroupDialog>
      {language === "zh_cn" ? <HeaderAd></HeaderAd> : null}
      <nav
        className={`${style.headerWrapper} ${style.headerWrapperTop} transition-6`}
      >
        <div className={style.headerContainer}>
          <div className={style.headerItem}>
            {/* <img src={logo} alt="" height={20} /> */}
            <Typography
              variant="h6"
              className={indexStyle.highlightBlack}
              fontWeight={800}
              letterSpacing={-0.5}
            >
              MCSMANAGER
            </Typography>
          </div>
          <div className={style.headerItem}>
            <div>
              {isLocalhost && (
                <Button
                  size="large"
                  onClick={handleClick}
                  style={{ color: textColor }}
                >
                  Language
                </Button>
              )}
              <Button
                size="large"
                onClick={handleToGithub}
                style={{ color: textColor }}
              >
                <span>Github</span>
              </Button>
              {language === "zh_cn" && (
                <Button
                  size="large"
                  onClick={openQQGroupDialog}
                  style={{ color: textColor }}
                >
                  <span> {t("index.QQ")}</span>
                </Button>
              )}

              {language !== "zh_cn" && (
                <Button
                  size="large"
                  onClick={handleToDiscord}
                  style={{ color: textColor }}
                >
                  <span>Discord</span>
                </Button>
              )}
            </div>
          </div>
        </div>
      </nav>
      {hoverMenu()}
      {/* <div style={{ height: "70px" }}></div> */}
    </>
  );
}

export default Header;
